import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import logo from '../../static/img/logo_black.png';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
    console.log(`Data is ${JSON.stringify(props)}`);
    this.domain = props.domain;
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        const activeState = this.state.active ? 'is-active' : '';
        this.setState({
          navBarActiveClass: activeState,
        });
      },
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} style={{ width: 25, height: 25 }} alt="Algasat Logo" />
              Algasat
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              onKeyDown={() => this.toggleHamburger()}
              role="button"
              tabIndex={0}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">

            </div>
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link>
              <Link className="navbar-item" to="/how-it-works">
                How It Works
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
              <a className="navbar-item" href={`https://app.${this.domain}`}>
                Login
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  domain: PropTypes.string,
};

export default Navbar;
